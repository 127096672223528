import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../../../template/Layout"

/**
 * Material
 */
import Head from "../../../head"
import { SectionTitle } from "../../common/SectionTitle"
import { ContactContainer } from "../common/ContactContainer"
import { ContactDescription } from "../common/ContactDescription"
import { ContactServiceDetail } from "./ContactServiceDetail"
import {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { useServicesFormContext } from "../../../utlis/FormValues"
import { invalidRequired } from "../../../utlis/check"

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

/**
 * Component
 */
export const ServiceConfirm: React.VFC<Props> = (props) => {
  const { state } = useServicesFormContext();

  useEffect(() => {
    if (invalidRequired(state.types) || invalidRequired(state.content) || invalidRequired(state.name) || invalidRequired(state.email)) {
      navigate('../');
    }
  }, []);

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head />
      <ContactContainer>
        <SectionTitle title='CONTACT' subTitle='お問い合わせ' />
        <ContactDescription title="サービスに関するお問い合わせ" step={2} />
        <ContactServiceDetail
          types={state.types || []}
          content={state.content || ''}
          company={state.company || ''}
          name={state.name || ''}
          email={state.email || ''}
          webSites={state.webSites || []}
          files={state.files || []}
        />
      </ContactContainer>
    </Layout>
  )
}
