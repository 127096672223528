import React, { useState } from "react"
import styled from "styled-components"
import { ContactService } from "../../../api/contacts"
import { ContactInputGroupConfirm } from "../common/ContactInputGroupConfirm"
import { ContactFormSendButton } from "../common/ContactFormSendButton"
import { uploadFile, ContactFileUploadForm } from "../common/ContactFileUploadForm"
import { contact } from "../../../data/contact"

/**
 * Element & Styles
 */
const Form = styled.form`
`

const FormNode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const HiddenItem = styled.input`
`

/**
 * Props
 */
type Props = {
  types: Array<string>
  content: string
  company: string
  name: string
  email: string
  webSites: Array<string>
  files: Array<HTMLInputElement>
}

/**
 * Component
 */
export const ContactServiceDetail: React.VFC<Props> = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  return (
    <>
      <Form
        name='サービスに関するお問い合わせ'
        method='POST'
        netlify-honeypot='bot-field'
        data-netlify='true'
        action='/contact/service/accepted'
        onSubmit={
          (e) => {
            return uploadFile(e);
          }
        }
      >
        <FormNode>
          <HiddenItem type='hidden' name='bot-field' />
          <HiddenItem type='hidden' name='form-name' value='サービスに関するお問い合わせ' />

          <ContactInputGroupConfirm
            label={contact.service.types.title}
            values={[...props.types]}
          />

          <ContactInputGroupConfirm
            label={contact.service.content.title}
            values={[props.content]}
          />

          <ContactInputGroupConfirm
            label={contact.service.company.title}
            values={[props.company]}
          />

          <ContactInputGroupConfirm
            label={contact.service.name.title}
            values={[props.name]}
          />

          <ContactInputGroupConfirm
            label={contact.service.email.title}
            values={[props.email]}
          />

          <ContactInputGroupConfirm
            label={contact.service.webSites.title}
            values={props.webSites}
          />

          <ContactInputGroupConfirm
            label={contact.service.files.title}
            values={props.files}
          />

          <ContactFormSendButton isDisabled={isDisabled} />

        </FormNode>
      </Form >
      <ContactFileUploadForm
        type={"service"}
        name={props.name}
        email={props.email}
        files={[...props.files]}
        setDisabled={setDisabled}
        uploadService={ContactService.uploadToGoogleDrive}
        callback={() => {
          document.querySelector('form').submit();
        }}
      />
    </>
  )
}
